@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.block{
  display:block;
}

.flex{
  display:flex;
}

.widthmax{
  width:100% !important;
}

.logostyle{
  width: 1cm !important;
  background: none;
  cursor:pointer;
}

.logostyleSidebar{
width: 8rem ;
margin-left: 2rem;
margin-top: 1rem;
margin-bottom: 1rem;
}


.backsectionhome{
  background-image: url("./pages/images/back.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-position:center right;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
  /* height: 90vh; */
}

.backsectionabout{
  background-image: url("./pages/images/back6.png");
  background-size: cover;
  background-attachment: fixed;
  background-position:bottom;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
}

.backsectionalbum{
  background-image: url("./pages/images/back1.png");
  background-size: cover;
  background-attachment: fixed;
  background-position:bottom;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
}



.backsectiondocuments{
  background-image: url("./pages/images/back3.png");
  background-size: cover;
  background-attachment: fixed;
  background-position:bottom;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
}

.backsectionservices{
  background-image: url("./pages/images/back8.png");
  background-size: cover;
  background-attachment: fixed;
  background-position:bottom ;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
}

.backsectioncontact{
  background-image: url("./pages/images/back5.png");
  background-size: cover;
  background-attachment: fixed;
  background-position:bottom;
  position: absolute;
  top: 0;
  left: 0;
  right:0;
  z-index: -1;
  width: 100%;
}

li {
    list-style-type: none;
}



.copyright{
  margin-bottom: 0;
  text-align: center;
  padding: 20px 0;
  background-color: #0d0d3b !important;
}

.homelink{
  font-size: 1.5rem !important;
}

.homelink:hover{
  text-decoration-color:#d32f2f59 !important;
  color: #d32f2f59 !important;
  cursor:pointer;
}

.hover:hover{
  text-decoration-color:#d32f2f59 !important;
  color: #d32f2f59 !important;
  cursor:pointer;
}

.paginationBttns a {
  padding:25%;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50%;
  color: #0d0d3b;
  cursor: pointer;
}

.paginationBttnsSmall a{
  padding:25%;
  margin-left: 1px;
  margin-right: 10px;
  border-radius: 50%;
  color: #0d0d3b;
  cursor: pointer;
}

.paginationBttns a:hover, 
.paginationBttnsSmall a:hover{
  color: #0d0d3b63;
}

.paginationActive a {
  color: white;
  background-color: #0d0d3b;
}

.paginationActive a:hover{
  background-color: #0d0d3b49;
}


.paginationDisabled a {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}


.paginationBttns {
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationBttnsSmall{
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  appearance: button;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* FOOTER */
.footersection{
  width: 100%;
  bottom:0;
  padding-top: 1%;
  z-index:  -1;
}

.footerBlocksLogo{
  margin-top: -0.5cm;
  width: 20%;
}

.footerFontSize {
  font-size: 16px !important;
}

.footerHeader{
  padding-top: 10px !important;
  font-size: 1.5rem !important;
}

.logoStyleFooter{
  width: 4cm;
  cursor: pointer;
}






@media (max-width: 299px ) {

  .footerFontSize{
    font-size: 0.55rem !important;
  }
  .footerHeader{
    font-size: 0.65rem !important;
  }
  .upperFooter{
    margin-left: 2rem;
  }

}

@media (max-width: 530px ) and (min-width: 300px) {
  .footerFontSize{
    font-size: 12px !important;
  }
  .footerHeader{
    font-size: 13px !important;
  }
  .upperFooter{
    margin-left: 2rem;
  }
}

@media (min-width: 531px ) and (max-width: 699px) {

  .footerFontSize{
    font-size: 12px !important;
  }
  .footerHeader{
    font-size: 13px !important;
  }
  .upperFooter{
    margin-left: 4rem;
  }
}

@media (min-width: 700px ) and (max-width:1400px){
  .footerFontSize{
    font-size: 14px !important;
  }
  .footerHeader{
    font-size: 18px !important;
  }
  .upperFooter{
    margin-left: 2rem;
  }
  .logoStyleFooter{
    width: 3cm;
    cursor: pointer;
  }

}

/* PRESENTATION */

.headerSize{
  font-size: 2rem !important;
}

.descSize{
  font-size: 0.875rem !important;
}




 /*CONTACT, SERVICES, ABOUT*/

.fontSize {
  font-size: 14px !important;
}


@media screen and (max-height: 599px){
  .cardHeight{
  height: 20vh !important;
  overflow-y: scroll !important;
  }
  .cardHeightServices{
    height: 20vh !important;
    overflow-y: scroll !important;
  }
  .headerSize{
    font-size: 2rem !important;
  }

}

@media screen and (min-height:600px) and (max-height: 915px) {
  .headerSize{
    font-size: 2rem !important;
  }

  .descSize{
    font-size: 0.7rem !important;
  }
   .fontSize{
    font-size: 14px !important;
   }
  .logostyle{
    width: 2cm !important;
    margin: 0.3cm !important;
  }

}


@media screen and (min-height:916px) and (max-height:1200px){

  .headerSize{
    font-size: 2.7rem !important;
  }

  .descSize{
    font-size: 1rem !important;
  }
   .fontSize{
    font-size: 16px !important;
   }
  .logostyle{
    width: 2cm !important;
    margin: 0.5cm !important;
  }

}

@media screen and (min-height:1200px) {

  .headerSize{
    font-size: 4rem !important;
  }

  .descSize{
    font-size: 1rem !important;
  }
   .fontSize{
    font-size: 16px !important;
   }
  .logostyle{
    width: 4cm !important;
    margin: 0.5cm !important;
  }
}

/* HOME PAGE */

.emptyLeftSide{
  margin-left: 50%;
}

.homeSection{
  z-index: 2;
  background-color: #f5f5f500; 
  width: 100%;
  height: 90vh;
}

.homeContainer{
  z-index: 9 !important;
  margin-left: 5% !important;
  width: 55% !important;
  text-align: left !important;
  margin-top: 40vh !important;
}

.homeHeaderSize{
  font-size: 5rem !important;
}

.homeFontSize{
  font-size:2rem !important;

}

@media (max-width: 299px ) {
  .emptyLeftSide{
    margin-left: 0%;
  }
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: center !important;
    margin-top: 30vh !important;
  }
  .homeHeaderSize{
    font-size: 2.5rem !important;
  }
  .homeFontSize{
    font-size:1rem !important;
  }
  .homelink{
    font-size: 0.7rem !important;
  }

}

@media (min-width: 300px ) and (max-width: 649px)  {
  .emptyLeftSide{
    margin-left: 0%;
  }
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: center !important;
    margin-top: 35vh !important;
  }
  .homeHeaderSize{
    font-size: 3rem !important;
  }
  .homeFontSize{
    font-size:1rem !important;
  }
  .homelink{
    font-size: 0.9rem !important;
  }
}


@media (min-width: 650px ) and (max-width: 959px)  {
  .emptyLeftSide{
    margin-left: 0%;
  }
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: center !important;
    margin-top: 30vh !important;
  }
  .homeHeaderSize{
    font-size: 4.5rem !important;
  }
  .homeFontSize{
    font-size: 2rem !important;
  }
  .homelink{
    font-size: 1.5rem !important;
  }

}

@media (min-width: 960px ) and (min-height:476px) and (max-height: 700px){
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: left !important;
    margin-top: 15vh !important;
  }
  .logostyle{
    width: 3cm !important;
  }
  .homeHeaderSize{
    font-size: 3.5rem !important;
  }
  .homeFontSize{
    font-size: 1.2rem !important;
  }
  .homelink{
    font-size: 0.85rem !important;
  }
  .cardHeight{
    height: 45vh !important;
  }
}

@media (min-width: 960px ) and (min-height:701px) and  (max-height: 900px){
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: left !important;
    margin-top: 23vh !important;
  }
  .logostyle{
    width: 4cm !important;
  }
  .homeHeaderSize{
    font-size: 4rem !important;
  }
  .homeFontSize{
    font-size: 1.5rem !important;
  }
  .homelink{
    font-size: 1rem !important;
  }
  .cardHeight{
    height: 45vh !important;
  }
}

@media (min-width: 960px ) and  (max-height: 475px){
  .homeSection{
    background-color: #f5f5f58e;
  }
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: left !important;
    margin-top:5vh !important;
  }
  .logostyle{
    width: 3cm !important;
  }
  .homeHeaderSize{
    font-size: 2rem !important;
  }
  .homeFontSize{
    font-size: 1rem !important;
  }
  .homelink{
    font-size: 0.7rem !important;
  }
}

@media (min-width: 900px ) and (max-height: 850px) {
  .container{
    padding-top: 10% !important;
  }
}



@media (min-width: 900px ) and (max-width: 1136px) and (height>900px){
  .homeContainer{
    z-index: 9 !important;
    margin-left: 5% !important;
    width: 90% !important;
    text-align: left !important;
    margin-top: 30vh !important;
  }
  .homeHeaderSize{
    font-size: 4.5rem !important;
  }
}


@media (min-width: 960px ) and (max-width: 1541px) and (height>900px){
.container{
  padding-top: 20% !important;
}
} 

@media (min-width: 900px )  and (height>900px){

  
    .homeHeaderSize{
      font-size: 5.2rem !important;
    }
    
    .homeFontSize{
      font-size:2rem !important;
    }
    .homelink{
      font-size: 1.5rem !important;
    }
  }



  .loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.274);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #0d0d3b transparent #0d0d3b transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }